import { Link, useNavigate } from '@remix-run/react'
import { cva } from 'class-variance-authority'
import * as React from 'react'
import { useRef } from 'react'
import { AriaButtonProps, useButton } from 'react-aria'
import { FiArrowLeft } from 'react-icons/fi'

import { cn } from '~/lib/utils'

export type VariantValues =
  | 'primary'
  | 'primary-inverted'
  | 'secondary'
  | 'outline'
  | 'danger'
  | 'highlight'
  | 'link-primary'
  | 'link-danger'

type Props = Omit<
  AriaButtonProps<'button'> | AriaButtonProps<'a'>,
  'isDisabled' | 'elementType'
> & {
  variant?: VariantValues
  size?: 'sm' | 'base'
  alignText?: 'left' | 'center' | 'right'
  active?: boolean
  disabled?: boolean
  stretched?: boolean
  loading?: boolean
  loadingAriaLabel?: string
  icon?: React.ReactNode
  rightIcon?: React.ReactNode
  children?: React.ReactNode
  className?: string
  role?: 'button' | 'combobox'
  style?: React.CSSProperties
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const Button = React.forwardRef(function Button(
  props: Props,
  ref: React.ForwardedRef<HTMLButtonElement & HTMLAnchorElement>,
) {
  const {
    variant = 'primary',
    size = 'base',
    active = false,
    disabled = false,
    stretched = false,
    loading = false,
    loadingAriaLabel = 'Loading',
    alignText,
    icon,
    rightIcon,
    children,
    className,
    ...restButtonProps
  } = props

  const isDisabled = disabled || loading
  const isLink = variant === 'link-primary' || variant === 'link-danger'
  const ariaLabel = loading ? loadingAriaLabel : props['aria-label']
  const localRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null)
  const buttonRef =
    (ref as React.RefObject<HTMLButtonElement & HTMLAnchorElement>) || localRef
  const { buttonProps, isPressed } = useButton(
    {
      ...restButtonProps,
      elementType: restButtonProps.href != null ? 'a' : 'button',
      isDisabled: isDisabled,
      'aria-label': ariaLabel,
    },
    buttonRef,
  )

  const button = cva(
    'group/button font-medium leading-none relative inline-block focus-visible:outline-none transition-all min-w-min h-fit shrink-0',
    {
      variants: {
        variant: {
          primary: '',
          'primary-inverted': '',
          secondary: '',
          outline: '',
          danger: '',
          highlight: '',
          'link-primary': '',
          'link-danger': '',
        },
        size: {
          sm: '',
          base: '',
        },
        isActive: {
          false: 'focus-visible:ring-orange-400 focus-visible:ring',
        },
        isDisabled: {
          true: 'cursor-not-allowed',
        },
        stretched: {
          true: 'w-full',
        },
        alignText: {
          left: 'text-left',
          center: 'text-center',
          right: 'text-right',
        },
        isIconButton: { true: '', false: '' },
        isOutlineButton: { true: '', false: '' },
        isLink: { true: '', false: '' },
        isDanger: { true: '', false: '' },
      },
      // prettier-ignore
      compoundVariants: [
        // Text sizes
        { size: 'sm', class: 'text-sm' },
        { size: 'base', class: 'text-base' },

        // Border radius
        { size: 'sm', isLink: false, class: 'rounded-md' },
        { size: 'base', isLink: false, class: 'rounded-lg' },
        { isLink: true, class: 'rounded-sm' },

        // Colors for primary buttons
        {
          variant: 'primary',
          class: 'focus-visible:ring-0 focus:shadow-inset-neon-flame focus-within:shadow-inset-neon-flame focus-visible:shadow-inset-neon-flame outline-none text-white'
        },
        { variant: 'primary', isDisabled: false, isActive: false, class: 'hover:bg-brand-blue-450' },
        { variant: 'primary', isDisabled: false, isActive: true, class: 'bg-brand-blue-450 shadow-inset-neon-flame' },
        { variant: 'primary', isDisabled: false, isActive: false, class: 'bg-brand-blue-500' },
        { variant: 'primary', isDisabled: true, class: 'bg-brand-blue-450/60' },

        // Colors for danger buttons
        {variant: 'danger', class: 'text-white focus:shadow-inset-brand-blue-2 focus-within:shadow-inset-brand-blue-2 focus-visible:shadow-inset-brand-blue-2 outline-none focus-visible:ring-0'},
        { variant: 'danger', isDisabled: false, isActive: false, class: 'hover:bg-[#AD341F] bg-error-400 text-white' },
        { variant: 'danger', isDisabled: true, class: 'bg-error-400/60' },
        { variant: 'danger', isActive: true, class: 'shadow-inset-brand-blue-2 bg-[#AD341F]' },

        // Colors for link primary buttons
        { variant: 'link-primary', class: 'text-brand-blue-500 border-current hover:text-brand-neon-flame hover:border-brand-neon-flame' },
        // { variant: 'link-primary', isDisabled: false, class: 'text-error-400' },
        // { variant: 'link-primary', isDisabled: true, class: 'text-grey-300' },
        // { variant: 'link-primary', isActive: true, class: 'shadow-inset-brand-blue' },

        // Colors for link danger buttons
        { variant: 'link-danger', isActive: false, class: 'hover:text-brand-neon-flame' },
        { variant: 'link-danger', isDisabled: false, class: 'text-error-400' },
        { variant: 'link-danger', isDisabled: true, class: 'text-grey-300' },
        // { variant: 'link-danger', isActive: true, class: 'ring-error-400/60 ring' },

        // Colors for primary-inverted buttons
        { variant: 'primary-inverted', class: 'bg-white text-black focus:shadow-inset-neon-flame focus-within:shadow-inset-neon-flame focus-visible:shadow-inset-neon-flame outline-none focus-visible:ring-0' },
        { variant: 'primary-inverted', isDisabled: false, class: 'hover:bg-white/80 text-black' },
        { variant: 'primary-inverted', isActive: true, class: 'shadow-inset-neon-flame bg-white/80' },

        // Colors for secondary buttons
        { variant: 'secondary', class: 'text-brand-blue-500 focus-visible:ring-0 outline-none hover:decoration-1 focus:shadow-inset-neon-flame focus-within:shadow-inset-neon-flame focus-visible:shadow-inset-neon-flame' },
        { variant: 'secondary', isActive: true, class: 'shadow-inset-neon-flame decoration-1' },

        // Colors for outline buttons
        { variant: 'outline', class: 'text-brand-blue-500  outline-none focus:shadow-inset-neon-flame focus-within:shadow-inset-neon-flame focus-visible:shadow-inset-neon-flame focus-visible:ring-0' },
        { variant: 'outline', isActive:false, isDisabled:false, class: 'bg-transparent  shadow-inset-brand-blue hover:bg-brand-green-50' },
        { variant: 'outline', isActive: true, class: 'bg-brand-green-50 shadow-inset-neon-flame' },
        { variant: 'outline', isDisabled: true, class: 'bg-brand-green-50 text-grey-300  shadow-[inset_0_0_0_1px_#668DA9]' },

        // Colors for highlight buttons
        { variant: 'highlight', class: 'focus-visible:ring-0 focus:shadow-inset-neon-flame focus-within:shadow-inset-neon-flame focus-visible:shadow-inset-neon-flame outline-none text-brand-blue-500 bg-brand-neon-spark' },
        { variant: 'highlight', isDisabled: false, isActive: false, class: 'hover:bg-[#B2EA3B]' },
        { variant: 'highlight', isDisabled: false, isActive: true, class: 'bg-[#B2EA3B] shadow-inset-neon-flame' },
        { variant: 'highlight', isDisabled: true, class: 'bg-brand-neon-spark/50 text-brand-blue-500/50' },

        // Vertical sizing for non-icon buttons
        { isLink: false, isIconButton: false, size: 'sm', isOutlineButton: false, class: 'py-[0.5625rem]' },
        { isLink: false, isIconButton: false, size: 'sm', isOutlineButton: true, class: 'py-2' },
        { isLink: false, isIconButton: false, size: 'base', isOutlineButton: false, class: 'py-3.5' },
        { isLink: false, isIconButton: false, size: 'base', isOutlineButton: true, class: 'py-[0.8125rem]' },

        // Horizontal sizing for non-icon buttons
        { isLink: false, isIconButton: false, size: 'sm', class: 'min-w-[4.375rem]' },
        { isLink: false, isIconButton: false, size: 'base', class: 'min-w-[5rem]' },
        { isLink: false, isIconButton: false, isOutlineButton: false, class: 'px-6' },
        { isLink: false, isIconButton: false, isOutlineButton: true, class: 'px-[1.375rem]' },

        // Sizing for icon buttons
        { isIconButton: true, class: 'grid place-content-center' },
        { isIconButton: true, isLink: false, size: 'sm', class: 'w-8 h-8' },
        { isIconButton: true, isLink: false, size: 'base', class: 'w-11 h-11' },
      ],
      defaultVariants: {
        variant: 'primary',
        size: 'base',
        isActive: false,
        isDisabled: false,
        stretched: false,
        isIconButton: false,
        isLink: isLink,
        isDanger: variant === 'danger' || variant === 'link-danger',
        alignText: stretched ? 'center' : 'left',
      },
    },
  )

  const isActive = props.active || isPressed
  const isIconButton = !children && (!!icon || !!rightIcon)
  const isOutlineButton = variant === 'outline'

  const buttonClassNames = button({
    variant,
    size,
    isActive,
    isDisabled,
    stretched,
    alignText,
    isIconButton,
    isOutlineButton,
  })

  const content = (
    <div className="relative">
      {/* Button label */}
      <span
        className={cn(
          'relative z-10 mt-[2px]',
          !isIconButton && 'flex justify-center items-center gap-x-2',
          loading ? 'invisible' : '',
        )}
      >
        {icon && <span className="flex-none">{icon}</span>}
        {children}
        {rightIcon}
      </span>

      {/* Loading indicator */}
      {loading && <ButtonSpinner buttonVariant={variant} />}

      {/* Underline - This is implemented as a border to
      ensure that the underline extends to icons, too */}
      {(variant === 'secondary' || isLink) && (
        <span
          className={cn(
            'absolute h-full w-full top-0 inset-x-0 z-0 group-disabled/button:hidden border-b border-current group-hover/button:text-opacity-85 group-hover/button:border-opacity-85',
          )}
        >
          &nbsp;
        </span>
      )}
    </div>
  )

  if (props.href != null) {
    return (
      <Link
        className={cn('h-fit', buttonClassNames, className)}
        {...buttonProps}
        to={props.href}
      >
        {content}
      </Link>
    )
  }

  return (
    <button
      ref={buttonRef}
      className={cn(buttonClassNames, className)}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      {...buttonProps}
    >
      {content}
    </button>
  )
})

function ButtonSpinner(props: { buttonVariant: Props['variant'] }) {
  const mainColor =
    props.buttonVariant === 'primary' || props.buttonVariant === 'danger'
      ? 'stroke-white'
      : props.buttonVariant === 'link-danger'
        ? 'stroke-error-400'
        : 'stroke-brand-blue-500'
  const highlightColor =
    props.buttonVariant === 'danger' || props.buttonVariant === 'link-danger'
      ? 'stroke-error-400'
      : 'stroke-[#ADC3D3]'

  return (
    <div
      className="absolute inset-0 flex justify-center items-center"
      aria-hidden
    >
      <svg
        className="w-[1em] h-[1em] text-white animate-spin fill-[none]"
        viewBox="0 0 18 18"
      >
        <circle cx="9" cy="9" r="8" strokeWidth="2" className={mainColor} />
        <path
          d="M16.9999 9C16.9999 7.71569 16.6907 6.45026 16.0984 5.31067C15.5061 4.17109 14.6482 3.19085 13.5971 2.45282C12.5461 1.71479 11.3327 1.24066 10.0597 1.07051C8.78676 0.900371 7.49151 1.03921 6.28351 1.4753"
          strokeWidth="2"
          strokeLinecap="round"
          className={highlightColor}
        />
      </svg>
    </div>
  )
}

export const BackButton = React.forwardRef(function BackButton(
  props: Props,
  ref: React.ForwardedRef<HTMLButtonElement & HTMLAnchorElement>,
) {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  return (
    <Button
      {...props}
      variant="secondary"
      onPress={goBack}
      icon={<FiArrowLeft className="text-brand-blue-500 mb-0.5 h-4 w-4" />}
      className="flex items-center gap-x-1 pl-0"
      ref={ref}
    >
      Back
    </Button>
  )
})
